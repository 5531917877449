import React from "react";
import { Link, Outlet } from "react-router-dom";
import logo from "../assets/img/logo.png";
export default function Layout() {
  return (
    <>
      <div>
        <nav className="grid grid-cols-12 place-items-center bg-gray-800 p-4 mx-auto">
          <div className="col-span-12 md:col-span-12">
            <div>
              <img src={logo} alt="logo" className="h-20" />
            </div>
          </div>
          <div className="col-span-12 md:col-span-12 place-items-center">
            <Link
              to="/portfolio"
              className="block mt-4 text-base lg:inline-block lg:mt-0 text-teal-300 hover:text-white mr-4"
            >
              Portfolio
            </Link>
            <Link
              to="/cars"
              className="block mt-4 text-base lg:inline-block lg:mt-0 text-teal-300 hover:text-white mr-4"
            >
              Cars
            </Link>
            <Link
              to="/homelab"
              className="block mt-4 text-base lg:inline-block lg:mt-0 text-teal-300 hover:text-white mr-4"
            >
              Homelab
            </Link>
          </div>
        </nav>
      </div>
      <hr />
      <div className="container mx-auto">
        <Outlet />
      </div>
    </>
  );
}
