import React from "react";
import warwick from "../assets/img/warwick.png"
import lucy from "../assets/img/lucy.png"
import lexy from "../assets/img/lexy.png"

export default function Cars() {
  return (
    <>
    <section className="block px-5 pb-10 text-white bg-center bg-no-repeat bg-cover bg-banner md:px-10 md:py-12 lg:px-10 lg:py-16"/>
    <div className="container mx-auto">
      <div className="grid grid-cols-10 place-items-center mx-auto gap-4">

        <div className="col-span-10 px-5 m-4">
          <div className="grid col-span-10 place-items-center">
            <h2 className="text-xl">LEXY - 2018 LEVORG (1.6) - Current</h2>
          </div>          
          <div className="grid grid-cols-10 place-items-center">
            <div className="grid col-span-3 drop-shadow-2xl">
              <img src={lexy} alt="Lexy" className="rounded-xl shadow-xl shadow-neutral-300" />
            </div>        
            <div className="col-span-7">
              <div className="grid grid-cols-12 gap-4">
                <div className="grid col-span-12">
                  Make: Subaru
                </div>
                <div className="grid col-span-12">
                  Model: Levorg
                </div>
                <div className="grid col-span-12">
                  Engine: 1.6L Turbo
                </div>              
              </div>              
            </div>     
          </div>                 
        </div>
        <div className="col-span-10 px-5 m-4">
          <div className="grid col-span-10 place-content-center">
            <h2 className="text-xl">LUCY - Grey Import From Japan</h2>
          </div>
          
          <div className="grid grid-cols-10 place-items-center">
            <div className="grid col-span-3 drop-shadow-2xl">
              <img src={lucy} alt="Lucy" className="rounded-xl shadow-xl shadow-neutral-300"></img>
            </div>
            <div className="col-span-7">
              <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                Make: Subaru
              </div>
              <div className="col-span-12">
                Model: Legacy GT
              </div>
              <div className="col-span-12">
                Engine: 2.0L Turbo
              </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-10 px-5 m-4">
          <div className="grid col-span-10 place-content-center">
            <h2 className="text-xl"> Warwick </h2>  
          </div>
          
          <div className="grid grid-cols-10 place-items-center">
            <div className="col-span-3">
              <img src={warwick} alt="Warwick" className="rounded-xl shadow-xl shadow-neutral-300"></img>
            </div>
            <div className="col-span-7">
              <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                Make: Subaru
              </div>
              <div className="col-span-12">
                Model: GC8 Imprezza
              </div>
              <div className="col-span-12">
                Engine: 2.0L Turbo
              </div>
              </div>
            </div>
          </div>          
        </div>
                
        </div>
        
        
        
      </div>      
    </>
  );
}
