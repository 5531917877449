import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import About from "./components/About";
import Cars from "./components/Cars";
import Portfolio from "./components/Portfolio";
import Homelab from "./components/Homelab";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/homelab" element={<Homelab />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
