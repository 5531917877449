import React from "react";
import banner from "../assets/img/banner.jpg";

export default function About() {
  return (
    <>
    <section className="block px-5 pb-10 text-white bg-center bg-no-repeat bg-cover bg-banner md:px-10 md:py-12 lg:px10 lg:py-16"/>
    <div className="container mx-auto bg-red-800">
      <div className="grid grid-cols-12 place-items-center p-4 mx-auto">
        <div className="col-span-12">
        <h1></h1>
        </div>
      </div>      
    </div>      
    </>
  );
}
